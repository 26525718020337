import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Button, { Size as ButtonSize } from './Button'
import { BLACK, PRIMARY, PRIMARY_MINUS_20 } from '../assets/colors'

type NaviItemProps = {
  to: string
  activeClassName: string
}

const Container = styled.nav`
  align-items: center;
  column-gap: 40px;
  display: flex;
  justify-content: space-between;
`

const StyledNavLink = styled(NavLink)<NaviItemProps>`
  border-bottom: 4px solid transparent;
  color: ${BLACK};
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  padding: 39px 5px 35px 5px;
  transition: border-bottom 100ms linear;

  &:hover {
    border-bottom: 4px solid ${PRIMARY_MINUS_20};
  }

  &.${({ activeClassName }) => activeClassName} {
    border-bottom: 4px solid ${PRIMARY};
    font-weight: 600;

    &:hover {
      border-bottom: 4px solid ${PRIMARY};
    }
  }
`

const Navigation: React.FC = () => (
  <>
    <Container>
      <StyledNavLink to="/" activeClassName="active">
        Home
      </StyledNavLink>
      {/* <StyledNavLink to="/pricing" activeClassName="active">
          Prijslijst
        </StyledNavLink> */}
      <StyledNavLink to="/gallery" activeClassName="active">
        Gallerij
      </StyledNavLink>
      <StyledNavLink to="/about" activeClassName="active">
        Over ons
      </StyledNavLink>
      <StyledNavLink to="/contact" activeClassName="active">
        Contact
      </StyledNavLink>
      <Button size={ButtonSize.small}>Boek online</Button>
    </Container>
  </>
)

export default Navigation
