import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import Button, { Size as ButtonSize } from './Button'
import { BLACK, GREY, WHITE_ALPHA_85 } from '../assets/colors'

type Props = {
  innerRef: any
  onToggleOpenMenu: () => void
}

type NaviItemProps = {
  to: string
  activeClassName: string
}

const Container = styled.div`
  display: flex;
  height: calc(100% - 100px);
  position: fixed;
  left: 0;
  top: 100px;
  user-select: none;
  width: 100%;
  z-index: 100;
`

const Overlay = styled.div`
  background-color: ${WHITE_ALPHA_85};
  height: 100%;
  position: fixed;
  left: 0;
  top: 98px;
  width: 100%;
`

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  justify-content: center;
  position: fixed;
  left: 0;
  top: 100px;
  width: 100%;
`

const StyledNavLink = styled(NavLink)<NaviItemProps>`
  border-bottom: 1px dashed ${GREY};
  color: ${BLACK};
  cursor: pointer;
  font-size: 2.4rem;
  font-weight: 400;
  min-width: 150px;
  padding: 20px 10px 20px 10px;
  text-align: center;

  &.${({ activeClassName }) => activeClassName} {
    font-weight: 700;
  }
`

const Menu: React.FC<Props> = ({ innerRef, onToggleOpenMenu }) => {
  // const targetRef = useRef()

  // useEffect(() => {
  //   console.log(targetRef)
  //   if (targetRef?.current) {
  //     disableBodyScroll(targetRef.current)
  //   }

  //   return () => {
  //     clearAllBodyScrollLocks()
  //   }
  // }, [])

  return (
    <Container ref={innerRef}>
      <Overlay />
      <Inner>
        <StyledNavLink to="/" activeClassName="active" onClick={onToggleOpenMenu}>
          Home
        </StyledNavLink>
        {/* <StyledNavLink to="/pricing" activeClassName="active" onClick={onToggleOpenMenu}>
          Prijslijst
        </StyledNavLink> */}
        <StyledNavLink to="/gallery" activeClassName="active" onClick={onToggleOpenMenu}>
          Gallerij
        </StyledNavLink>
        <StyledNavLink to="/about" activeClassName="active" onClick={onToggleOpenMenu}>
          Over ons
        </StyledNavLink>
        <StyledNavLink to="/contact" activeClassName="active" onClick={onToggleOpenMenu}>
          Contact
        </StyledNavLink>
        <Button className="margin-t-30" size={ButtonSize.small}>
          Boek online
        </Button>
      </Inner>
    </Container>
  )
}

export default React.memo<Props>(Menu)
